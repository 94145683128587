<template>

  <div class="add-popup-comp">
    <div class="title van-hairline--bottom">
      {{ title }}
    </div>
    <div role="button"
         :class="{'popup-item__option': true, active: isActive(i) }"
         v-for="(option, i) in options"
         :key="i"
         @click="select(option, i)">
      {{ option.label }}
    </div>
  </div>

</template>

<script>
export default {
  name: 'add-stamp-comp',
  props: {
    title: {
      type: String,
      default: () => '请选择'
    },
    options: Array
  },
  data() {
    return {
      activeIndex: null
    }
  },
  methods: {
    select(option, index) {
      this.activeIndex = index
      this.$emit('select', option)
      // 清除激活的下标 因为这里的激活样式仅仅是点击提示作用
      setTimeout(() => {
        this.activeIndex = null
      }, 1000)
    },
    isActive(index) {
      return this.activeIndex === index
    }
  }
}
</script>

<style lang='stylus'>
.add-popup-comp
  position relative
  & .title, .popup-item__option
    text-align center
    height 40px
    line-height 40px
    font-size 16px
    &.active
      color #1989fa
  & .title
    color rgba(69, 90, 100, 0.6)
</style>
