var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-popup-comp" },
    [
      _c("div", { staticClass: "title van-hairline--bottom" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _vm._l(_vm.options, function (option, i) {
        return _c(
          "div",
          {
            key: i,
            class: { "popup-item__option": true, active: _vm.isActive(i) },
            attrs: { role: "button" },
            on: {
              click: function ($event) {
                return _vm.select(option, i)
              },
            },
          },
          [_vm._v(" " + _vm._s(option.label) + " ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }