<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" :title="title" fixed>
      <fb-button
        slot="left"
        icon="back"
        @click="backToNative()"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
      <div slot="right">
        <div class="header-menu">
          <div class="icon" @click="onSearch">
            <van-icon name="search" />
          </div>
          <div class="icon" @click="onAdd">
            <van-icon name="plus" />
          </div>
        </div>
      </div>
    </fb-header>

    <join-organization v-if="!this.organizationId" />

    <div class="oa-stamp">
      <filter-dropdown
        :filters="
          this.filterKV && this.filterKV.filtersStore
            ? this.filterKV.filtersStore
            : filters
        "
        :selectDate="`${startTime}-${endTime}`"
        @select="handleFilterSelect"
        @dateChange="handleFilterDateChange"
      />
    </div>
    <van-popup v-model="addPopupVisible" position="top" get-container="body">
      <add-popup :options="addPopupOptions" @select="handleAddSelect" />
    </van-popup>
    <van-popup v-model="searchVisible" position="top" get-container="body">
      <van-field
        left-icon="search"
        v-model="keyword"
        placeholder="检索案件名称、案号、申请人名字"
        @input="searchInput"
        ref="inputsearch"
      />
    </van-popup>
    <!-- 案件列表 -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="!isRequest"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="oa-case-list">
          <div
            class="oa-case"
            v-for="(item, index) in caseList"
            :key="index"
            @click="jump(item, index)"
          >
            <div class="oa-case-header">
              <div class="oa-case-title" v-if="item.category === 'CASE'">
                案件用章
              </div>
              <div class="oa-case-title" v-else>业务用章</div>
              <div class="oa-case-userInfo">
                <img :src="item.avatarUrl" alt="" />
                <div>{{ item.applicantName }}</div>
              </div>
              <div class="oa-case-status" v-if="item.progress === 'UNSUBMENT'">
                未提交
              </div>
              <div class="oa-case-status" v-if="item.progress === 'UNAUDITED'">
                待审核
              </div>
              <div class="oa-case-status" v-if="item.progress === 'APPROVED'">
                审核通过
              </div>
              <div
                class="oa-case-status"
                v-if="item.progress === 'CERTIFIED_FAILED'"
              >
                审核不通过
              </div>
            </div>
            <div class="oa-case-center">
              <div>{{ item.itemName }}</div>
            </div>
            <div class="oa-case-bottom">
              <div class="oa-case-time">申请时间：{{ item.useTime }}</div>
              <div class="oa-case-long">{{ item.useTimeText }}</div>
              <div class="oa-case-btn">
                <button
                  v-for="(btnItem, btnIndex) in item.btnList"
                  :key="btnIndex"
                  @click.stop="btnClick(btnItem, item, index)"
                >
                  {{ btnItem }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </fb-page>
</template>

<script>
import goBackMixin from '@mixins/goBack'
import { mapState, mapActions, mapMutations } from 'vuex'
import AddPopup from '../../components/addPopup'
import FilterDropdown from '../../components/filterDropdown'
import JoinOrganization from '@/components/join-organization'

export default {
  name: 'oa-stamp',
  mixins: [goBackMixin],
  components: {
    AddPopup,
    JoinOrganization,
    FilterDropdown
  },
  data() {
    return {
      title: '我的盖章',
      searchVisible: false, // 搜索弹框
      addPopupVisible: false,
      addPopupOptions: [
        {
          label: '案件用章',
          value: '0'
        },
        {
          label: '业务用章',
          value: '1'
        }
      ],
      filters: [
        {
          label: '种类',
          id: 'type',
          noSelect: '种类',
          options: [
            {
              text: '案件用章',
              value: 'CASE'
            },
            {
              text: '业务用章',
              value: 'WORK'
            }
          ]
        },
        {
          label: '用章项目',
          noSelect: '用章项目',
          id: 'item',
          options: []
        },
        {
          label: '我申请的',
          id: 'userType',
          options: [
            {
              text: '我申请的',
              value: 'APPLICANT'
            },
            {
              text: '我审批的',
              value: 'APPROVER'
            },
            {
              text: '抄送我的',
              value: 'RECIPIENT'
            }
          ]
        },
        {
          label: '创建时间',
          id: 'date',
          options: [
            {
              text: '案件用章',
              value: '0'
            },
            {
              text: '业务用章',
              value: '1'
            }
          ]
        },
        {
          label: '状态',
          id: 'status',
          noSelect: '状态',
          options: [
            {
              text: '未提交',
              value: 'UNSUBMENT'
            },
            {
              text: '待审核',
              value: 'UNAUDITED'
            },
            {
              text: '审核通过',
              value: 'APPROVED'
            },
            {
              text: '审核不通过',
              value: 'CERTIFIED_FAILED'
            }
          ]
        }
      ],
      userType: 'APPLICANT',
      caseList: [], // 案件列表
      keyword: '',
      stamperItem: '',
      progress: '', // 审批进度（UNSUBMENT未提交）（UNAUDITED待审核）（APPROVED审核通过）（CERTIFIED_FAILED审核不通过）
      category: '', // 案件/业务用章，枚举（CASE案件用章）（WORK业务用章）
      page: 0,
      size: 10,
      startTime: '',
      endTime: '',
      defaultPage: 0, // 是否显示缺省页 0正常  1缺省页
      isRequest: true, // 是否可以继续请求
      loading: true, // 是否处于加载状态
      refreshing: false
    }
  },
  computed: {
    ...mapState('user', {
      userId: state => state.userInfo.id
    }),
    ...mapState('user', ['organizationId']),
    ...mapState('oa', ['filterKV'])
  },
  methods: {
    ...mapActions('user', ['getOrganizationId']),
    ...mapMutations('oa', ['setFilters']),
    // 搜索按钮点击事件
    onSearch() {
      this.searchVisible = true
      setTimeout(() => {
        this.$refs.inputsearch.focus()
      }, 100)
    },
    // 添加按钮点击事件
    onAdd() {
      this.openAddPopup()
    },
    // #添加弹窗# 选择事件处理
    handleAddSelect(payload) {
      console.log(payload, '#添加弹窗# 选择事件处理')
      this.closeAddPopup()
      this.setFiltersInPage()
      this.$router.push({
        name: 'oa-addStamp',
        query: {
          label: payload.label,
          case: '',
          isAdd: 0
        }
      })
    },
    // 打开 #添加弹窗#
    openAddPopup() {
      this.addPopupVisible = true
    },
    // 关闭 #添加弹窗#
    closeAddPopup() {
      this.addPopupVisible = false
    },
    // #筛选器# 选择处理
    handleFilterSelect(payload) {
      console.log('handleFilterSelect', payload)
      switch (payload.category.id) {
        case 'userType':
          this.userType = payload.option.value
          this.filtersStore = this.filters.map(item => {
            if (item.id === payload.category.id) {
              item.label = payload.option.text
            }
            return item
          })
          break
        case 'type':
          if (this.category === payload.option.value) {
            this.category = ''
          } else {
            this.category = payload.option.value
          }
          this.filtersStore = this.filters.map(item => {
            if (item.id === payload.category.id) {
              item.label = payload.option.text
            }
            return item
          })
          break
        case 'status':
          if (this.progress === payload.option.value) {
            this.progress = ''
          } else {
            this.progress = payload.option.value
          }
          this.filtersStore = this.filters.map(item => {
            if (item.id === payload.category.id) {
              item.label = payload.option.text
            }
            return item
          })
          break
        case 'item':
          if (this.stamperItem === payload.option.value) {
            this.stamperItem = ''
          } else {
            this.stamperItem = payload.option.value
          }
          this.filtersStore = this.filters.map(item => {
            if (item.id === payload.category.id) {
              item.label = payload.option.text
            }
            return item
          })
          break
      }
      this.caseList = []
      this.page = 0
      this.isRequest = true
      this.getCaseList()
    },
    /* #筛选器-日期选择器# 改变处理
     * @param payload date类型
     */
    handleFilterDateChange(payload) {
      console.log('handleFilterDateChange', payload)
      this.caseList = []
      this.startTime = payload[0]
      this.endTime = payload[1]
      this.page = 0
      this.isRequest = true
      this.getCaseList()
    },
    // 获取案件列表
    getCaseList() {
      this.loading = true
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })

      const params = {
        userId: this.userId,
        keyword: this.keyword,
        stamperItem: this.stamperItem,
        progress: this.progress,
        category: this.category,
        userType: this.userType,
        page: this.page,
        size: this.size,
        startTime: this.startTime,
        endTime: this.endTime
      }
      this.$axios
        .get(`${this.$base}/management/stamper/record`, {
          params: params
        })
        .then(res => {
          if (res.data.data) {
            if (res.data.data.totalElements === 0) {
              // 无数据显示缺省页面
              this.caseList = res.data.data.content
              this.defaultPage = 1
              this.isRequest = false
            } else {
              if (res.data.data.content.length > 0) {
                for (var i = 0; i < res.data.data.content.length; i++) {
                  let avatarUrl = ''
                  res.data.data.content[i].approveRecordUsers.map((item, i) => {
                    if (item.type === 'APPLICANT') {
                      avatarUrl = item.user.avatarUrl
                    }
                  })
                  res.data.data.content[i].avatarUrl = avatarUrl
                  // 申请时间
                  const useTime = new Date(
                    res.data.data.content[i].useTime.replace(/-/g, '/')
                  ).getTime()
                  res.data.data.content[i].useTimeText = this.getDateDiff(
                    useTime
                  )
                  switch (res.data.data.content[i].progress) {
                    case 'UNSUBMENT':
                      res.data.data.content[i].btnList = ['申请', '删除']
                      break
                    case 'UNAUDITED':
                      res.data.data.content[i].approveRecordUsers.forEach(
                        item => {
                          if (item.type === 'APPROVER') {
                            if (item.user.id === this.userId) {
                              res.data.data.content[i].btnList = [
                                '批准',
                                '驳回'
                              ]
                            } else {
                              res.data.data.content[i].btnList = []
                            }
                          }
                        }
                      )
                      break
                    case 'APPROVED':
                      res.data.data.content[i].btnList = []
                      break
                    case 'CERTIFIED_FAILED':
                      res.data.data.content[i].btnList = ['申请', '删除']
                      break
                  }
                }
              }
              this.caseList = this.caseList.concat(res.data.data.content)
              if (this.page + 1 >= res.data.data.totalPages) {
                this.isRequest = false
              } else {
                this.isRequest = true
              }
            }
          } else {
            this.isRequest = false
          }
          this.loading = false
          this.refreshing = false
          this.$toast.clear()
        })
    },

    // 申请时间格式化
    getDateDiff(dateTimeStamp) {
      var result = ''
      var minute = 1000 * 60
      var hour = minute * 60
      var day = hour * 24
      var now = new Date().getTime()
      var diffValue = now - dateTimeStamp
      var dayC = diffValue / day
      var hourC = diffValue / hour
      var minC = diffValue / minute
      if (dayC >= 1) {
        result = '' + parseInt(dayC) + '天前'
      } else if (hourC >= 1) {
        result = '' + parseInt(hourC) + '小时前'
      } else {
        result = '' + parseInt(minC) + '分钟前'
      }
      return result
    },

    // 获取用章项目
    async getStamperItem() {
      const stamperItem = await this.$axios.get(
        `${this.$base}/management/organization/${
          this.organizationId
        }/stamper/items`
      )
      const options = stamperItem.data.data.map(item => ({
        value: item.item,
        text: item.item
      }))
      this.filters[1].options = options
    },

    /**
     * 按钮点击事件
     * name 操作名称
     * item 数据内容
     * index 第几条数据
     */
    btnClick(name, item, index) {
      if (name === '删除') {
        this.$dialog
          .confirm({
            title: '提示',
            message: '删除不可恢复！确定删除吗？'
          })
          .then(async () => {
            // on confirm
            const response = await this.$axios.post(
              `${this.$base}/management/stamper/record/delete/${item.id}`
            )
            const { code } = response.data
            if (code === 200) {
              this.$toast.success('删除成功')
              this.caseList.splice(index, 1)
            }
          })
          .catch(() => {
            // on cancel
          })
      } else if (name === '批准' || name === '驳回') {
        const params = {
          approveRecordUsers: item.approveRecordUsers,
          category: item.category,
          collections: item.collections,
          progress: name === '批准' ? 'APPROVED' : 'CERTIFIED_FAILED',
          recipient: item.recipient,
          remarks: item.remarks,
          stamperItem: item.stamperItem,
          type: item.type,
          useTime: new Date(item.useTime).getTime(),
          number: item.number
        }
        if (item === 'CASE') {
          delete params.number
        }
        this.$axios
          .put(
            `${this.$base}/management/stamper/record/${item.id}/progress`,
            params
          )
          .then(res => {
            if (res.data.code === 200) {
              this.$toast.success('审批成功')
              setTimeout(() => {
                this.onRefresh()
              }, 1000)
            } else {
              this.$toast.fail('审批失败')
            }
          })
      } else if (name === '申请') {
        const params = {
          approveRecordUsers: item.approveRecordUsers,
          category: item.category,
          collections: item.collections,
          progress: 'UNAUDITED',
          recipient: item.recipient,
          remarks: item.remarks,
          stamperItem: item.stamperItem,
          type: item.type,
          useTime: new Date().getTime(),
          number: item.number
        }
        if (item === 'CASE') {
          delete params.number
        }
        this.$axios
          .put(
            `${this.$base}/management/stamper/record/${item.id}/progress`,
            params
          )
          .then(res => {
            if (res.data.code === 200) {
              this.$toast.success('申请成功')
              setTimeout(() => {
                this.onRefresh()
              }, 1000)
            } else {
              this.$toast.fail('申请失败')
            }
          })
      } else {
        this.jump(item, index)
      }
    },
    // 跳转事件
    jump(item, index) {
      if (item.progress === 'APPROVED' || item.progress === 'UNAUDITED') {
        this.setFiltersInPage()
        this.$router.push({
          name: 'stamp-info',
          query: {
            commonId: item.id,
            isAdd: 1
            // case: JSON.stringify(item)
          }
        })
      } else {
        this.setFiltersInPage()
        this.$router.push({
          name: 'oa-addStamp',
          query: {
            label: item.category === 'CASE' ? '案件用章' : '业务用章',
            case: JSON.stringify(item),
            isAdd: 1
          }
        })
      }
    },

    // 搜索功能
    searchInput() {
      this.caseList = []
      this.page = 0
      this.isRequest = true
      this.getCaseList()
    },

    // 上拉加载事件
    onLoad() {
      this.page++
      this.loading = true
      this.getCaseList()
    },

    // 下拉刷新
    onRefresh() {
      this.caseList = []
      this.page = 0
      this.isRequest = true
      this.getCaseList()
    },

    // 获取组织id
    async getId() {
      await this.getOrganizationId()
      if (!this.organizationId) {
        return this.$bus.$emit('join-org', { show: true })
      }
    },

    // 设置查询条件
    setFiltersInPage() {
      this.setFilters({
        stamperItem: this.stamperItem,
        category: this.category,
        progress: this.progress,
        startTime: this.startTime,
        endTime: this.endTime,
        keyword: this.keyword,
        filtersStore: this.filtersStore
      })
    }
  },
  created() {
    // isUser: 0个人  1律所
    if (this.filterKV) {
      this.stamperItem = this.filterKV.stamperItem
      this.category = this.filterKV.category
      this.progress = this.filterKV.progress
      this.startTime = this.filterKV.startTime
      this.endTime = this.filterKV.endTime
      this.keyword = this.filterKV.keyword
    }
    console.log(this.filterKV)
    if (this.$route.query.isUser === '0') {
      this.filters.splice(3, 1)
    } else {
      this.userType = ''
      this.filters.splice(2, 1)
    }
  },
  async mounted() {
    await this.getId()
    console.log(this.userId)
    this.getStamperItem()
    this.getCaseList()
  }
}
</script>

<style lang="stylus">
.oa-stamp
  position relative
.oa-case-list
  padding 0 10px
  box-sizing border-box
  & .oa-case
    border-radius 5px
    border 1px solid rgba(204, 204, 204, 1)
    width 100%
    height auto
    padding 10px 0
    box-sizing border-box
    margin-bottom 10px
    & .oa-case-header
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      padding 0 10px
      box-sizing border-box
    & .oa-case-title
      font-size 14px
      color #666666
    & .oa-case-userInfo
      font-size 14px
      display flex
      flex-direction row
      align-items center
      img
        width 24px
        height 24px
        margin-right 5px
    & .oa-case-status
      font-size 16px
    & .oa-case-center
      font-size 16px
      font-weight bold
      box-sizing border-box
      padding 10px 15px
      border-bottom 1px solid rgba(204, 204, 204, 1)
      div
        display -webkit-box
        -webkit-box-orient vertical
        -webkit-line-clamp 2
        overflow hidden
    & .oa-case-bottom
      display flex
      flex-direction row
      align-items center
      box-sizing border-box
      padding 10px 10px 0 10px
      & .oa-case-time
        font-size 12px
        color #999999
        margin-right 10px
      & .oa-case-btn
        display flex
        flex-direction row
        align-items center
        justify-content flex-end
        flex 1
        button
          width 55px
          height 30px
          border-radius 5px
          border 1px solid rgba(0, 121, 254, 1)
          background none
          color #0079FE
          font-size 14px
</style>
