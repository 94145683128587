var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.backToNative()
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
          _c("div", { attrs: { slot: "right" }, slot: "right" }, [
            _c("div", { staticClass: "header-menu" }, [
              _c(
                "div",
                { staticClass: "icon", on: { click: _vm.onSearch } },
                [_c("van-icon", { attrs: { name: "search" } })],
                1
              ),
              _c(
                "div",
                { staticClass: "icon", on: { click: _vm.onAdd } },
                [_c("van-icon", { attrs: { name: "plus" } })],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      !this.organizationId ? _c("join-organization") : _vm._e(),
      _c(
        "div",
        { staticClass: "oa-stamp" },
        [
          _c("filter-dropdown", {
            attrs: {
              filters:
                this.filterKV && this.filterKV.filtersStore
                  ? this.filterKV.filtersStore
                  : _vm.filters,
              selectDate: `${_vm.startTime}-${_vm.endTime}`,
            },
            on: {
              select: _vm.handleFilterSelect,
              dateChange: _vm.handleFilterDateChange,
            },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.addPopupVisible,
            callback: function ($$v) {
              _vm.addPopupVisible = $$v
            },
            expression: "addPopupVisible",
          },
        },
        [
          _c("add-popup", {
            attrs: { options: _vm.addPopupOptions },
            on: { select: _vm.handleAddSelect },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.searchVisible,
            callback: function ($$v) {
              _vm.searchVisible = $$v
            },
            expression: "searchVisible",
          },
        },
        [
          _c("van-field", {
            ref: "inputsearch",
            attrs: {
              "left-icon": "search",
              placeholder: "检索案件名称、案号、申请人名字",
            },
            on: { input: _vm.searchInput },
            model: {
              value: _vm.keyword,
              callback: function ($$v) {
                _vm.keyword = $$v
              },
              expression: "keyword",
            },
          }),
        ],
        1
      ),
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function ($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing",
          },
        },
        [
          _c(
            "van-list",
            {
              attrs: {
                finished: !_vm.isRequest,
                "finished-text": "没有更多了",
              },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _c(
                "div",
                { staticClass: "oa-case-list" },
                _vm._l(_vm.caseList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "oa-case",
                      on: {
                        click: function ($event) {
                          return _vm.jump(item, index)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "oa-case-header" }, [
                        item.category === "CASE"
                          ? _c("div", { staticClass: "oa-case-title" }, [
                              _vm._v(" 案件用章 "),
                            ])
                          : _c("div", { staticClass: "oa-case-title" }, [
                              _vm._v("业务用章"),
                            ]),
                        _c("div", { staticClass: "oa-case-userInfo" }, [
                          _c("img", {
                            attrs: { src: item.avatarUrl, alt: "" },
                          }),
                          _c("div", [_vm._v(_vm._s(item.applicantName))]),
                        ]),
                        item.progress === "UNSUBMENT"
                          ? _c("div", { staticClass: "oa-case-status" }, [
                              _vm._v(" 未提交 "),
                            ])
                          : _vm._e(),
                        item.progress === "UNAUDITED"
                          ? _c("div", { staticClass: "oa-case-status" }, [
                              _vm._v(" 待审核 "),
                            ])
                          : _vm._e(),
                        item.progress === "APPROVED"
                          ? _c("div", { staticClass: "oa-case-status" }, [
                              _vm._v(" 审核通过 "),
                            ])
                          : _vm._e(),
                        item.progress === "CERTIFIED_FAILED"
                          ? _c("div", { staticClass: "oa-case-status" }, [
                              _vm._v(" 审核不通过 "),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "oa-case-center" }, [
                        _c("div", [_vm._v(_vm._s(item.itemName))]),
                      ]),
                      _c("div", { staticClass: "oa-case-bottom" }, [
                        _c("div", { staticClass: "oa-case-time" }, [
                          _vm._v("申请时间：" + _vm._s(item.useTime)),
                        ]),
                        _c("div", { staticClass: "oa-case-long" }, [
                          _vm._v(_vm._s(item.useTimeText)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "oa-case-btn" },
                          _vm._l(item.btnList, function (btnItem, btnIndex) {
                            return _c(
                              "button",
                              {
                                key: btnIndex,
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.btnClick(btnItem, item, index)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(btnItem) + " ")]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }